@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply min-h-screen bg-gray-100;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
select,
textarea {
  @apply focus:outline-2 focus:outline-sky-500 focus:ring-sky-500 focus:border-sky-500 appearance-none block w-full shadow-sm text-sm border border-gray-300 px-3 py-2;
}

select {
  @apply cursor-pointer;
}